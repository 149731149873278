import { css } from "styled-components";
import { hexToRGB } from "helpers/TextHelpers";
import { compose } from "ramda";
import { darken, saturate } from "polished";

const hover = compose(darken(0.15), saturate(0.15));

const whiteGlow = css`
  box-shadow:
    0 0 0 3px rgba(192, 203, 206, 0.35),
    0px 16px 64px rgba(8, 8, 8, 0.12),
    0px 8px 32px rgba(8, 8, 8, 0.1),
    0px 4px 16px rgba(8, 8, 8, 0.08),
    0px 2px 8px rgba(8, 8, 8, 0.06),
    0px 1px 4px rgba(8, 8, 8, 0.04),
    0px 0px 2px rgba(8, 8, 8, 0.02);

  &:hover,
  &:focus {
    box-shadow:
      0 0 0 5px rgba(192, 203, 206, 0.35),
      0px 32px 64px rgba(8, 8, 8, 0.18),
      0px 16px 32px rgba(8, 8, 8, 0.16),
      0px 8px 16px rgba(8, 8, 8, 0.14),
      0px 4px 8px rgba(8, 8, 8, 0.12),
      0px 2px 4px rgba(8, 8, 8, 0.1),
      0px 0px 2px rgba(8, 8, 8, 0.08);
  }
`;

interface colorGlowInterface {
  backgroundColor: string;
}

const colorGlow = css<colorGlowInterface>`
  ${({ backgroundColor }) => {
    const { r, g, b } = hexToRGB(backgroundColor) ?? {};
    return `box-shadow: 0 0 0 3px rgba(192, 203, 206, 0.35),
      0px 0px 2px rgba(${r}, ${g}, ${b}, 0.1),
      0px 1px 4px rgba(${r}, ${g}, ${b}, 0.12),
      0px 2px 8px rgba(${r}, ${g}, ${b}, 0.14),
      0px 4px 16px rgba(${r}, ${g}, ${b}, 0.16),
      0px 8px 32px rgba(${r}, ${g}, ${b}, 0.18),
      0px 16px 64px rgba(${r}, ${g}, ${b}, 0.2);
    `;
  }}
  &:hover, 
  &:focus {
    ${({ backgroundColor }) => {
      const { r, g, b } = hexToRGB(hover(backgroundColor)) ?? {};
      return `box-shadow: 0 0 0 5px rgba(${r}, ${g}, ${b}, 0.12), 
        0px 0px 2px rgba(${r}, ${g}, ${b}, 0.16),
        0px 2px 4px rgba(${r}, ${g}, ${b}, 0.18),
        0px 4px 8px rgba(${r}, ${g}, ${b}, 0.2),
        0px 8px 16px rgba(${r}, ${g}, ${b}, 0.22),
        0px 16px 32px rgba(${r}, ${g}, ${b}, 0.24),
        0px 32px 64px rgba(${r}, ${g}, ${b}, 0.26);
    `;
    }}
  }
`;

interface glowInterface {
  backgroundColor: string;
  white?: boolean;
  glow?: boolean;
}

const glow = css<glowInterface>`
  ${({ backgroundColor, glow, white }) => {
    const { r, g, b } = hexToRGB(backgroundColor) ?? {};
    return glow ? (white ? whiteGlow : colorGlow) : ``;
  }}
`;

export default glow;
